var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('seamless-access-button',{staticClass:"mb-4",attrs:{"redirect":_vm.redirectTo('satosa')}}),_c('google-oauth-btn',{attrs:{"redirect":_vm.redirectTo('google')}},[_vm._v("Sign up with Google")]),_c('div',{staticClass:"d-flex align-center mt-5 mb-2"},[_c('v-divider'),_c('span',{staticClass:"caption px-2"},[_vm._v("OR")]),_c('v-divider')],1),_vm._v(" Please provide your details below to set up your account: "),_c('v-form',{staticClass:"mt-4",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{staticClass:"first-name-field",attrs:{"outlined":"","dense":"","counter":50,"label":"First Name","rules":[_vm.rules.nonEmpty],"required":""},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}}),_c('v-text-field',{staticClass:"last-name-field",attrs:{"outlined":"","dense":"","counter":50,"label":"Last Name","rules":[_vm.rules.nonEmpty],"required":""},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}}),_c('v-text-field',{staticClass:"password-first-field",attrs:{"outlined":"","dense":"","counter":50,"label":"Password","required":"","type":"password","rules":_vm.password.length
                    ? [
                          _vm.rules.passwordHasNoFirstSpecialCharacter,
                          _vm.rules.passwordAllowedCharacters,
                          _vm.rules.passwordLength,
                          _vm.rules.passwordHasUppercase,
                          _vm.rules.passwordHasLowercase,
                          _vm.rules.passwordHasNumber,
                          _vm.rules.passwordHasSpecialCharacter
                      ]
                    : [_vm.rules.nonEmpty]},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('v-text-field',{staticClass:"password-second-field",attrs:{"outlined":"","dense":"","counter":50,"label":"Verify Password","required":"","type":"password","rules":[_vm.rules.passwordMatch, _vm.rules.passwordLength]},model:{value:(_vm.password2),callback:function ($$v) {_vm.password2=$$v},expression:"password2"}}),_c('v-text-field',{attrs:{"outlined":"","dense":"","value":_vm.signupUserDetails.email,"label":"E-mail","required":"","readonly":""}}),_c('v-btn',{attrs:{"block":"","color":"primary","disabled":!_vm.valid},on:{"click":function($event){return _vm.signIn()}}},[_vm._v("Sign in")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }